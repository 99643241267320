/* eslint-disable */
<template>
  <div>
    <div class="sticky">
      <v-card flat rounded="0">
        <v-container>
          <div class="d-flex">
            <v-btn @click="$router.go(-1)" class="my-auto" icon>
              <v-icon>fal fa-arrow-left</v-icon>
            </v-btn>
            <div class="my-auto ml-2">
              Select Vehicle
            </div>
          </div>
        </v-container>
      </v-card>
      <v-card class="rounded-b-l rounded-t-0 py-2" color="grey lighten-4" flat>
        <v-container class="py-0">
          <v-chip-group v-model="selectedType">
            <v-chip active-class="blue lighten-4" filter value="Bike"
              >Bike</v-chip
            >
            <v-chip active-class="blue lighten-4" value="Scooter"
              >scooter</v-chip
            >
          </v-chip-group>
        </v-container>

        <v-container class="py-0" v-if="selectedType">
          <div v-if="isLoading" class="scroll-container py-1">
            <v-skeleton-loader
              v-for="i in 5"
              :key="i"
              type="image"
              width="50"
              height="50"
              class="mr-2"
            ></v-skeleton-loader>
          </div>
          <div v-else class="scroll-container py-1">
            <v-card
              v-for="data in manufacturer"
              :key="data.manufacturer"
              :class="
                selectedManufacturer === data.manufacturer ? 'borderedCard' : ''
              "
              :color="
                selectedManufacturer === data.manufacturer
                  ? 'white lighten-1'
                  : 'white lighten-1'
              "
              class="mr-2"
              @click="selectedManufacturer = data.manufacturer"
            >
              <v-img
                class="brand-image"
                :src="data.manufacturerData.imgColor"
                :lazy-src="data.manufacturerData.imgColor"
                contain
                max-height="50"
                max-width="50"
              ></v-img>
            </v-card>
          </div>
        </v-container>
      </v-card>
    </div>
    <v-container
      class="py-4"
      v-if="selectedManufacturer && modelList && !preselectedVehicle"
    >
      <div v-if="loadingModel">
        <v-row>
          <v-col v-for="i in 6" :key="i" md="2" cols="6">
            <v-skeleton-loader type="card"></v-skeleton-loader>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row>
          <v-col cols="6" md="2" v-for="item in modelList" :key="item.id">
            <!-- :class="selectedModel === item ? 'rounded-xl' : ''" -->
            <v-card
              flat
              class="rounded-b-xl rounded-t-0"
              @click="selectModel(item)"
            >
              <v-img
                :src="item.heroImg"
                :lazy-src="item.heroImg"
                class="grey lighten-4"
                min-height="100%"
                width="100%"
              >
              </v-img>
              <v-card
                dark
                class="text-center "
                :color="themeColor ? themeColor : '#4F42D8'"
              >
                <v-container class="text-caption">
                  <div>
                    {{ item.displayName }}
                  </div>
                  <div>Starts @{{ item.price | currency }}</div>
                </v-container>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <!-- **** select vehicle area **** -->

    <!-- **** pre selection area **** -->
    <v-col
      md="6"
      v-if="selectedManufacturer && modelList && preselectedVehicle"
      class=""
    >
      <v-col md="10" class="mx-auto pa-0">
        <v-col class="px-0">
          <p class="text-left mt-2 header-m mb-2 px-0">
            {{ preselectedVehicle.model }}
          </p>
          <v-btn @click="changeDefaultSelection">Change Vehicle</v-btn>
        </v-col>
        <div>
          <div>
            <v-card>
              <v-img
                :src="preselectedVehicle.heroImg"
                class="grey lighten-4"
                min-height="50px"
                width="100%"
              >
                <!--                <div class="fill-height bottom-gradient"></div>-->
              </v-img>
              <v-card-title
                class=" pa-0 ma-0"
                :color="themeColor ? themeColor : 'primary'"
              >
                <p
                  class="ml-4 mt-1 mb-1 subtitle-2"
                  style="font-size: 12px; color: #FFF; text-overflow: ellipsis;overflow:hidden; white-space: nowrap; "
                >
                  {{ preselectedVehicle.displayName }}
                </p>
              </v-card-title>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-col>

    <v-bottom-sheet v-model="showBottomSheet">
      <v-card>
        <v-sheet v-if="selectedModel" max-width="100%">
          <v-tabs
            v-model="vehicleTab"
            :color="themeColor ? themeColor : 'primary'"
            fixed-tabs
          >
            <v-tabs-slider
              :color="themeColor ? themeColor : 'primary'"
            ></v-tabs-slider>
            <v-tab style="background-color: #FFF">Finance </v-tab>
            <v-tab>Details</v-tab>
          </v-tabs>
        </v-sheet>
        <v-sheet v-if="selectedModel" max-width="100%">
          <v-tabs-items v-model="vehicleTab">
            <v-tab-item>
              <v-container>
                <v-row>
                  <v-col cols="5">
                    <p class="caption">
                      On Road Price
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <v-icon small> fal fa-info-circle </v-icon>
                          </span>
                        </template>
                        <span
                          >This is to get a basic idea of your loan requirement,
                          Prices subject to vary.</span
                        >
                      </v-tooltip>
                    </p>
                  </v-col>
                  <v-col class="text-right">
                    <p class="subtitle-2">
                      {{
                        selectedModel.price.toLocaleString("en-IN", {
                          style: "currency",
                          currency: "INR",
                        })
                      }}
                      /-
                    </p>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="5">
                    <p class="caption">
                      Down-Payment <br />
                      <span class="caption" style="color:green;font-size:12px"
                        >({{ Math.floor(dpPercent * 100) }}% of vehicle
                        cost)</span
                      >
                    </p>
                  </v-col>
                  <v-col class="text-right text-no-wrap">
                    <v-icon
                      class=""
                      :color="themeColor ? themeColor : 'primary'"
                      @click="subDP"
                      >fal fa-minus-circle
                    </v-icon>
                    <span class="px-1 subtitle-2">{{
                      downpayment.toLocaleString("en-IN", {
                        style: "currency",
                        currency: "INR",
                      })
                    }}</span>
                    <v-icon
                      class=""
                      :color="themeColor ? themeColor : 'primary'"
                      @click="addDP"
                      >fal fa-plus-circle
                    </v-icon>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5">
                    <p class="caption">Tenure</p>
                  </v-col>
                  <v-col class="text-right text-no-wrap">
                    <v-icon
                      :color="themeColor ? themeColor : 'primary'"
                      @click="subTenure"
                      >fal fa-minus-circle
                    </v-icon>
                    <span class="px-3 subtitle-2">{{ tenure }} years</span>
                    <v-icon
                      :color="themeColor ? themeColor : 'primary'"
                      @click="addTenure"
                      >fal fa-plus-circle
                    </v-icon>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5">
                    <p class="caption">EMI</p>
                  </v-col>
                  <v-col class="text-right">
                    <p class="subtitle-2">
                      {{
                        totalEmi.toLocaleString("en-IN", {
                          style: "currency",
                          currency: "INR",
                        })
                      }}
                      /Month
                    </p>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="5">
                    <p class="caption">
                      Vehicle Name
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <v-icon small> fal fa-info-circle </v-icon>
                          </span>
                        </template>
                        <span
                          >Make, color and variant can be changed at the time of
                          visit to dealership. This does not affect your loan
                          eligibility.</span
                        >
                      </v-tooltip>
                    </p>
                  </v-col>
                  <v-col class="text-right">
                    <p class="subtitle-2">
                      {{ selectedModel.model }}
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-container>
                <v-row>
                  <v-col>
                    <p class="caption">
                      Fuel Economy
                    </p>
                  </v-col>
                  <v-col class="text-right">
                    <p class="subtitle-2">
                      {{ selectedModel.mileage + " Km/l" || "-" }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p class="caption">Torque</p>
                  </v-col>
                  <v-col class="text-right">
                    <p class="subtitle-2">
                      {{ selectedModel.torque + " bHp" || "-" }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p class="caption">Engine</p>
                  </v-col>
                  <v-col class="text-right">
                    <p class="subtitle-2">
                      {{ selectedModel.engine + " cc" || "-" }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p class="caption">Ignition</p>
                  </v-col>
                  <v-col class="text-right">
                    <p class="subtitle-2">
                      {{ selectedModel.ignition || "-" }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p class="caption">Max Speed</p>
                  </v-col>
                  <v-col class="text-right">
                    <p class="subtitle-2">
                      {{ selectedModel.maxSpeed || "-" }} Km/h
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-sheet>
        <v-container class="section6 pa-4 text-center">
          <v-row v-if="selectedModel" class="pa-2 d-flex justify-center">
            <v-btn
              block
              class="white--text"
              :color="themeColor ? themeColor : 'primary'"
              depressed
              height="60"
              @click="submit"
            >
              Apply for Loan
              <v-icon class="my-0 py-0 ml-2">fal fa-angle-right</v-icon>
            </v-btn>
            <v-btn
              text
              :color="themeColor ? themeColor : 'primary'"
              class="my-4"
              @click="disclaimer = true"
              >Disclaimer</v-btn
            >
          </v-row>
        </v-container>
      </v-card>
    </v-bottom-sheet>

    <v-dialog v-model="disclaimer" persistent width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Terms of Use
        </v-card-title>
        <v-card-text>
          <ul>
            <br />
            <li>We require Pincode to check for service area.</li>
            <li>
              Multiple usage or providing false information might impact your
              cibil score.
            </li>
            <li>
              We respect your Privacy and We DO NOT Store/Process/Sell your
              information to any 3rd party / vendor.
            </li>
            <li>
              Your information is confidential and is only used for loan
              application approval purpose.
            </li>
            <li>
              We do not use your confidential information for
              Targeted/Personalized Ads.
            </li>
            <li>
              By clicking Accept you hereby agree To our
              <a
                class="link"
                @click="
                  $router.push(
                    '/finance/TermsConditions#/finance/TermsConditions'
                  )
                "
                >Terms and Conditions</a
              >
              and our <a href="">Privacy Policy</a>.
            </li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :color="themeColor ? themeColor : 'primary'"
            text
            @click="disclaimer = false"
          >
            I Understood
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="maxDPAlert" max-width="350">
      <v-card>
        <v-card-title class="text-h5">
          Hold on
        </v-card-title>
        <v-card-text>
          That's the maximum downpayment you can select.
          <v-alert
            border="left"
            colored-border
            color="deep-purple accent-4"
            elevation="2"
            class="my-4"
          >
            Chances of loan rejection is lower when down payment is higher.
          </v-alert>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="minDPAlert" max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Hold on
        </v-card-title>
        <v-card-text>
          That's the minimum downpayment you can select.
          <v-alert
            border="left"
            colored-border
            color="red-darken"
            elevation="2"
            class="my-4"
          >
            Chances of loan rejection is higher when down payment is lower.
          </v-alert>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import * as _ from "lodash";
export default {
  data: () => ({
    loadingModel: true,
    themeColor: "#4F42D8",
    showBottomSheet: false,
    disclaimer: false,
    minDownPayment: 30000,
    maxDownPayment: 0,
    minTenure: 1,
    maxTenure: 0,
    test: true,
    isLoading: null,
    modelList: null,
    selectedModel: null,
    manufacturer: null,
    selectedManufacturer: null,
    vehicleTab: null,
    downpayment: 45000,
    types: [],
    selectedType: "Scooter",
    tenure: 3,
    preselectedVehicle: null,
    preselectedVehicleId: null,
    maxDPAlert: false,
    minDPAlert: false,
    dpPercent: 0.1,
  }),

  watch: {
    selectedType() {
      this.getManufacturer();
      this.getVehicles();
    },
    selectedManufacturer() {
      this.getVehicles();
    },
    modelList(newValue) {
      this.minDownPayment = Math.ceil(0.1 * newValue[0].price);
      this.maxDownPayment = Math.floor(0.8 * newValue[0].price);
      this.dpPercent = 0.25;
      this.downpayment = Math.ceil(0.25 * newValue[0].price);
    },
    selectedModel(newValue) {
      this.minDownPayment = Math.ceil(0.1 * newValue.price);
      this.maxDownPayment = Math.floor(0.8 * newValue.price);
      this.dpPercent = 0.25;
      this.downpayment = Math.ceil(0.25 * newValue.price);
    },
  },
  mounted() {
    this.themeColor = localStorage.getItem("themeColor") || "#4F42D8";
    let leadSource = localStorage.getItem("source") || "Organic";
    var eventName = leadSource
      ? "explore-bikes-" + leadSource
      : "explore-bikes-organic";
    window.fbq("track", eventName);
    this.$gtag.event(eventName, {
      event_category: "click-event",
      event_label: "page view",
      value: 1,
    });
  },
  async created() {
    this.preselectedVehicleId = this.$route.query.id;
    if (this.preselectedVehicleId) {
      this.getTypes();
      this.getManufacturer();
      try {
        const { data } = await this.$api.HTTP2.get(
          `finance/vehicle?id=` + this.preselectedVehicleId
        );
        console.log(data);
        this.selectedType = data.type ? data.type : "Bike";
        this.selectedManufacturer = data.manufacturer;
        this.selectedModel = data;
        this.preselectedVehicle = data;
      } finally {
        this.isLoading = false;
      }
    } else {
      this.getTypes();
      this.getManufacturer();
    }
  },

  computed: {
    totalEmi() {
      var loanAmount = this.selectedModel.price - this.downpayment;
      var interest = 0.12 * loanAmount;
      var tenure = this.tenure * 12;
      var amount = loanAmount + interest;
      return Math.floor(amount / tenure);
    },
  },

  methods: {
    changeDefaultSelection() {
      (this.preselectedVehicle = null),
        (this.preselectedVehicleId = null),
        (this.selectedModel = null);
    },
    addDP() {
      var newDownpayment =
        Number(this.downpayment) + Math.floor(0.05 * this.selectedModel.price);
      if (newDownpayment > this.maxDownPayment) {
        this.downpayment = this.maxDownPayment;
        this.maxDPAlert = true;
      } else {
        this.downpayment = newDownpayment;
        this.dpPercent += 0.05;
      }
    },

    subDP() {
      var newDownpayment =
        Number(this.downpayment) - Math.floor(0.05 * this.selectedModel.price);
      if (newDownpayment < this.minDownPayment) {
        this.downpayment = this.minDownPayment;
        this.minDPAlert = true;
      } else {
        this.downpayment = newDownpayment;
        this.dpPercent -= 0.05;
      }
    },

    addTenure() {
      var newTenure = this.tenure + 1;
      if (newTenure > 3) {
        this.tenure = 3;
      } else {
        this.tenure = newTenure;
      }
    },

    subTenure() {
      var newTenure = Number(this.tenure) - 1;
      if (newTenure < 1) {
        this.tenure = 1;
      } else {
        this.tenure = newTenure;
      }
    },

    async getTypes() {
      this.isLoading = true;
      try {
        const { data } = await this.$api.HTTP2.get(`finance/types`);
        this.types = data;
      } finally {
        this.isLoading = false;
      }
    },

    async getManufacturer() {
      this.isLoading = true;
      try {
        const { data } = await this.$api.HTTP2.get(
          `finance/manufacturer?type=${this.selectedType}`
        );
        this.manufacturer = data;
        this.selectedManufacturer = this.manufacturer[0].manufacturer;
      } finally {
        this.isLoading = false;
      }
    },

    async getVehicles() {
      this.loadingModel = true;
      try {
        const { data } = await this.$api.HTTP2.get(
          `finance/vehicle?manufacturer=${this.selectedManufacturer}&type=${this.selectedType}`
        );
        this.modelList = _.orderBy(data, "price", "asc");
      } finally {
        this.loadingModel = false;
      }
    },

    submit() {
      var storeData = {};
      storeData.vehicle = this.selectedModel;
      storeData.tenure = this.tenure;
      storeData.marginMoney = this.downpayment;
      try {
        this.$store.dispatch("setVehicleSelected", storeData);
      } catch {
        alert(
          "Oops, cannot add vehicle at the moment, please try later or refresh the page."
        );
      } finally {
        let leadSource = localStorage.getItem("source") || "Organic";
        var eventName = leadSource
          ? "apply-for-loan-" + leadSource
          : "apply-for-loan-organic";
        window.fbq("track", eventName);
        this.$gtag.event(eventName, {
          event_category: "click-event",
          event_label: "page view",
          value: 1,
        });
        this.$router.push("finance/pincode");
      }
    },

    selectModel(model) {
      this.selectedModel = model;
      this.showBottomSheet = true;
      // this.$vuetify.goTo(target);
    },
  }, // end of export default
};
</script>
<style scoped>
.borderedCard {
  border: 1px solid rgb(69, 69, 69) !important;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9;
}
</style>
